export const AUTH_URL = process.env.REACT_APP_AUTHORIZATION_URL
export const API_URL = process.env.REACT_APP_API_URL
export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL
export const NOTIFICATIONS_API_URL = process.env.REACT_APP_NOTIFICATIONS_API_URL
export const TASKS_API_URL = process.env.REACT_APP_TASKS_API_URL
export const SHARE_VIDEOS_URL = process.env.REACT_APP_SHARE_VIDEOS_URL
export const BROADCASTS_API_URL = process.env.REACT_APP_BROADCASTS_API_URL
export const AI_SEARCH_URL = process.env.REACT_APP_AI_SEARCH_URL
export const BROADCAST_CHANNEL_ID = 'broadcast_channel'
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
export const CHAT_ONLY_USERIDS = ['679d0cc1b2ff2cf8965a45ef'] // Gabriella Cohen
