import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from './Layout'
import Chat from './Chat'
import Networks from './Networks'
import Staff from './Staff'
import SearchReclips from './SearchReclips'
import VectorSearch from './VectorSearch'
import Deepgram from './Deepgram'
import Swagger from './Swagger'
import Settings from './Settings'
import ReclipsMap from './ReclipsMap'
import ReclipPlayer from './ReclipPlayer'
import SharePlayer from './SharePlayer'
import GPT from './GPT'
import Tags from './Tags'
import Schools from './Schools'
import Reactions from './Reactions'
import TeamReclipStory from './TeamReclipStory'
import Clusters from './Clusters'
import EvergreenSuggestions from './EvergreenSuggestions'
import OnboardingSuggestions from './OnboardingSuggestions'
import Harsh from './Harsh'
import FreePhoneNumbers from './FreePhoneNumbers'
import Chatbots from './Chatbots'
import { useAuth } from 'store/selectors'
import { CHAT_ONLY_USERIDS } from 'const'

const Main = () => {
  const { user } = useAuth()

  if (CHAT_ONLY_USERIDS.includes(user.id))
    return (
      <Layout>
        <Routes>
          <Route path="/chat/*" element={<Chat />} />
          <Route path="*" element={<Navigate to="/chat" replace />} />
        </Routes>
      </Layout>
    )

  return (
    <Layout>
      <Routes>
        <Route path="/chat/*" element={<Chat />} />
        <Route path="/networks" element={<Networks />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/search" element={<SearchReclips />} />
        <Route path="/vector-search" element={<VectorSearch />} />
        <Route path="/transcription" element={<Deepgram />} />
        <Route path="/swagger/*" element={<Swagger />} />
        <Route path="/map" element={<ReclipsMap />} />
        <Route path="/r/:source_id" element={<ReclipPlayer />} />
        <Route path="/s/:share_code" element={<SharePlayer />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/gpt" element={<GPT />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/reactions" element={<Reactions />} />
        <Route path="/team-reclip-story" element={<TeamReclipStory />} />
        <Route path="/clusters" element={<Clusters />} />
        <Route path="/evergreen" element={<EvergreenSuggestions />} />
        <Route path="/onboarding" element={<OnboardingSuggestions />} />
        <Route path="/harsh" element={<Harsh />} />
        <Route path="/free-phone-numbers" element={<FreePhoneNumbers />} />
        <Route path="/chatbots/*" element={<Chatbots />} />
        <Route path="*" element={<Navigate to="/chat" replace />} />
      </Routes>
    </Layout>
  )
}

export default Main
